define(['app', 'accessibilityFocusHelper'], (app, accessibilityFocusHelper) => {

  const reevooReviews = () => {
    const component = {};

    component.config = {
      selectors: {
        desktopProductDescription: '.athenaProductPage_breakpoint-lg_productDescription',
        westendHeader: '.westendHeader',
        desktopId: '#product-description-heading-lg-14',
        mobileId: '#product-description-heading-14'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.westendHeader = document.querySelector(component.config.selectors.westendHeader);
      component.desktopReviewsTab = document.querySelector(component.config.selectors.desktopId);
      component.mobileReviewsTab = document.querySelector(component.config.selectors.mobileId);
      
      component.bind();
    };

    component.bind = () => {
      component.element.addEventListener('click', component.reevooBadgeClickHandler);
    };

    component.scrollToReevooTab = (reevooTab) => {
      if (component.westendHeader && reevooTab) {
        app.element.scrollTo(reevooTab.offsetTop - component.westendHeader.clientHeight, 500);
        accessibilityFocusHelper.focus(reevooTab);
        reevooTab.click();
      }
    };

    component.reevooBadgeClickHandler = (e) => {
      e.preventDefault();
      component.desktopProductDescription = document.querySelector(component.config.selectors.desktopProductDescription);

      if(component.desktopProductDescription) {
        const style = window.getComputedStyle(component.desktopProductDescription);
        const isDesktop = style.getPropertyValue('display') !== 'none';
        const isMobile = !isDesktop;
        
        if (isDesktop && component.desktopReviewsTab) {
          component.scrollToReevooTab(component.desktopReviewsTab);
        }
  
        if (isMobile && component.mobileReviewsTab) {
          component.scrollToReevooTab(component.mobileReviewsTab);
        }
      }
    };

    return component;
  };

  return reevooReviews;
});
